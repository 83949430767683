:root {
  --extra-small: 4px;
  --small: 8px;
  --small-plus: 12px;
  --medium: 16px;
  --medium-plus: 24px;
  --large: 32px;
  --large-plus: 40px;
  --extra-large: 48px;
  --line-height: 24px;
}

html, body {
  margin: 0;
  padding: 0;
}

.text-disabled {
  color: var(--disabled-color);
}

.warning-yellow {
  color: var(--status-warning-color);
}

.error-red {
  color: var(--status-error-color);
}

.success-green {
  color: var(--status-success-color);
}

